<template>
    <v-container>
        <action-flow-step
            v-if="actionFlowRootId"
            :AFId="actionFlowRootId"
            :title="actionFlowSubject"
            :key="actionFlowRootId"
            hide-run
        />
        <v-row v-if="useProject">
            <fragment v-if="pageCount">
                <v-col class="col-8">
                    <storage-king-source-file-link-transaction-list
                        :project="useProject"
                        @transaction-selected="transactionSelected"
                        :key="JSON.stringify(useProject)"
                        :selected-transaction="selectedTransaction"
                        :tablePageNumber="tablePageNumber"
                        @update:table-page="val => (tablePageNumber = val)"
                        :tableSortBy="tableSortBy"
                        @update:table-sort-by="val => (tableSortBy = val)"
                        :tableSortDesc="tableSortDesc"
                        @update:table-sort-desc="val => (tableSortDesc = val)"
                    />
                </v-col>
                <v-col class="col-4">
                    <file-download-field
                        collectionName="storageKingProject"
                        :collectionId="useProject.id"
                        file-type="Combined Purchases Original Copies"
                        stamp-page-numbers
                    />
                    <storage-king-source-file-link-pages-selector
                        v-if="useProject && !useProject.APPurchasesLink"
                        :project="useProject"
                        :selectedTransaction="selectedTransaction"
                        :pages="pages"
                        :numPages="pageCount"
                        @project-updated="updateProject"
                        @confirm-complete="confirmComplete"
                        @confirm-failed-action-flow="confirmFailedActionFlow"
                        :key="JSON.stringify(useProject)"
                    />
                </v-col>
            </fragment>
            <btn
                v-if="useProject && !useProject.APPurchasesLink"
                color="success"
                @click="prepareFileAPPurchasesExtract"
                small
                label="Prepare AP Purchases File"
            />
        </v-row>
    </v-container>
</template>

<script>
import api from '@/api';

import StorageKingSourceFileLinkTransactionList from '@/views/storageKing/components/APPurchasesLink/TransactionList';
import StorageKingSourceFileLinkPagesSelector from '@/views/storageKing/components/APPurchasesLink/PagesSelector';
import ActionFlowStep from '@/components/common/ActionFlowStep';

export default {
    name: 'StorageKingPurchasePayablePanel',
    props: { project: Object },
    components: {
        StorageKingSourceFileLinkTransactionList,
        StorageKingSourceFileLinkPagesSelector,
        ActionFlowStep,
    },
    data() {
        return {
            tablePageNumber: 0,
            tableSortBy: null,
            tableSortDesc: null,
            pages: [],
            pagesString: '',
            pageCount: 0,
            selectedTransaction: null,
            useProject: null,
            actionFlowSubject: null,
            actionFlowRootId: null,
        };
    },
    methods: {
        setPages() {
            try {
                if (this.useProject && this.useProject.APPurchasesExtract) {
                    this.pageCount = this.useProject.APPurchasesExtract.pageCount;
                }

                if (this.pageCount) {
                    this.pages = this.useProject.APPurchasesExtract.extractedData.transactions
                        .map(transaction => transaction.pages)
                        .filter(transaction => transaction)
                        .flat();
                    console.log('setPages', this.pages);
                }
                this.pagesString = this.pages.toString();
            } catch (e) {
                console.error(e.message);
            }
        },
        transactionSelected(val) {
            this.selectedTransaction = val[0].value ? val[0].item : null;
            console.log('this.selectedTransaction', this.selectedTransaction);
        },
        updateProject(project) {
            if (JSON.stringify(project) !== JSON.stringify(this.useProject)) {
                this.useProject = project;
                this.setPages();
            }
        },
        confirmComplete(project) {
            if (JSON.stringify(project) !== JSON.stringify(this.useProject)) {
                this.$emit('project-updated', project);
            }
        },
        confirmFailedActionFlow(actionFlow) {
            console.log('actionFlow failed', actionFlow);
            this.actionFlowRootId = actionFlow.rootId;
            this.actionFlowSubject = actionFlow.actionType;
        },
        async prepareFileAPPurchasesExtract() {
            this.$store.dispatch('setLoading', 'Generating Invoices File');
            const projectObj = await api.post(
                this.$store,
                'storageking/preparefileappurchasesextract',
                {
                    storageKingProjectId: this.useProject.id,
                },
            );

            const result = projectObj.data;

            console.log({ result });

            if (result.success === false) {
                this.$store.dispatch('setError', result.message);
            } else {
                const project = projectObj.data;
                if (project && project.APPurchasesExtract) {
                    this.$emit('project-updated', project);
                }
            }
            this.$store.dispatch('setLoading');
        },
    },
    async created() {
        this.useProject = this.project;
        this.setPages();
    },
};
</script>
