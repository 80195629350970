<template>
    <v-container>
        <v-row v-for="(fileType, idx) in fileTypes" :key="fileType.fileType">
            <v-divider class="col-12"></v-divider>
            <file-upload-field
                :label="fileType.fileType"
                collectionName="storageKingProject"
                :collectionId="project.id"
                :fileType="fileType.fileType"
                :accept="fileType.accept"
                showToggleCompact
                compact
                :multiple="fileType.multiple"
                @set-files="event => setFiles(idx, event)"
            />
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'StorageKingFilePanel',
    props: { project: Object },
    data() {
        return {
            fileTypes: [
                { fileType: 'MYOB Supplier Purchases', accept: '.xlsx,.csv' },
                { fileType: 'Purchases Original Copies', accept: '.pdf', multiple: true },
                { fileType: 'Storeman Income Charged', accept: '.xlsx' },
                { fileType: 'Rent Invoice', accept: '.pdf'}
            ],
        };
    },
    methods: {
        setFiles(idx, event) {
            console.log('setFiles', idx, event, this.fileTypes,":");
            this.fileTypes[idx].files = event;
            this.$emit(
                'files-updated',
                this.fileTypes.reduce(
                    (accum, fileType) => [...accum, ...(fileType.files || [])],
                    [],
                ),
            );
        },
    },
    mounted() {
        console.log('FILE PANEL', this.project.id);
    },
};
</script>
