<template>
    <v-container>
        <smart-table
            :headers="headers"
            :items="data.items"
            class="elevation-1"
            dense
            :itemsPerPage="10"
        />
        <div class="d-flex">Sales Total <currency-format class="ml-3" :value="data.totalAmount" /></div>

        <v-container v-if="missed && missed.length">
            The following Storage King Codes need to be added to rPro Item.
            <v-chip v-for="oneMissed in missed" color="red" :key="oneMissed">
                {{ oneMissed }}
            </v-chip>
        </v-container>
        <v-container v-if="!data.balanced">
            <v-chip color="red">
                Uploaded spreadsheet transactions do not match the total. Please check and re-upload.
            </v-chip>
        </v-container>
    </v-container>
</template>
<script>
import CurrencyFormat from '@/components/format/Currency';

export default {
    name: 'StorageKingPurchasePayablePreviewRawData',
    components: { CurrencyFormat },
    props: { storageKingProjectId: String, data: Object, missingItemCodes: Array },
    data() {
        return {
            lines: [],
            headers: [
                {
                    text: 'StoreMan Code',
                    align: 'center',
                    sortable: false,
                    value: 'storageKingCode',
                },
                {
                    text: 'Item',
                    align: 'center',
                    sortable: false,
                    value: 'itemCode',
                },
                {
                    text: 'Description',
                    align: 'left',
                    sortable: false,
                    value: 'description',
                },
                {
                    text: 'Amount',
                    align: 'right',
                    sortable: false,
                    value: 'amount',
                    customComponent: 'Currency',
                },
                {
                    text: 'Tax',
                    align: 'right',
                    sortable: false,
                    value: 'taxAmount',
                    customComponent: 'Currency',
                },
            ],
            missed: null,
        };
    },
    methods: {
        init() {
            const missed = new Set();
            this.missingItemCodes.map(oneMissed => missed.add(oneMissed.storageKingCode));
            this.missed = Array.from(missed).sort();
        },
    },
    mounted() {
        this.init();
    },
};
</script>
