<template>
    <v-container>
        <smart-table
            :headers="headers"
            :items="lines"
            class="elevation-1"
            dense
            :itemsPerPage="10"
            :key="`datatable${lines.length}`"
        />
        <div class="d-flex">
            <v-container>
                <v-row>
                    <v-col md="2">
                        Expenses Total
                    </v-col>
                    <v-col>
                        <currency-format class="ml-3" :value="data.totalAmount" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col md="2">
                        Calculated
                    </v-col>
                    <v-col>
                        <v-chip
                            v-if="data.calculatedTotal !== data.totalAmount"
                            color="red"
                            outlined
                            ><currency-format class="ml-3" :value="data.calculatedTotal"
                        /></v-chip>
                        <v-chip
                            v-if="data.calculatedTotal === data.totalAmount"
                            color="green"
                            outlined
                            ><currency-format class="ml-3" :value="data.calculatedTotal"
                        /></v-chip>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container v-if="data && data.missingMYOBAccounts && data.missingMYOBAccounts.length">
            The following Storage King GL Accounts need to be added to rPro Item.<br />
            <v-chip v-for="oneMissed in data.missingMYOBAccounts" color="red" :key="oneMissed" outlined>
                {{ oneMissed }}
            </v-chip>
        </v-container>
        <v-container v-if="data && data.datesAsDocumentNumbers && data.datesAsDocumentNumbers.length">
            The following Bills have dates as document references.  Please fix and re-upload the excel file.<br />
            <v-chip v-for="oneMissed in data.datesAsDocumentNumbers" color="red" :key="oneMissed" outlined>
                {{ oneMissed }}
            </v-chip>
        </v-container>
        <v-container v-if="data && data.missingVendorNames && data.missingVendorNames.length">
            The following Storage King Vendor Names need to be added to a 2easy Vendor.<br />
            <v-chip v-for="oneMissed in data.missingVendorNames" color="red" :key="oneMissed" outlined>
                {{ oneMissed }}
            </v-chip>
        </v-container>
    </v-container>
</template>
<script>
import CurrencyFormat from '@/components/format/Currency';

export default {
    name: 'StorageKingPurchasePayablePreviewRawData',
    components: { CurrencyFormat },
    props: { data: Object },
    data() {
        return {
            missed: null,
            lines: [],
            headers: [
                {
                    text: 'Code',
                    align: 'center',
                    sortable: false,
                    value: 'vendorCode',
                    customComponent: 'CodeChip',
                },
                {
                    text: 'Vendor',
                    align: 'center',
                    sortable: false,
                    value: 'vendorName',
                },
                {
                    text: 'Date',
                    align: 'center',
                    sortable: false,
                    value: 'transactionDate',
                },
                {
                    text: 'Reference',
                    align: 'left',
                    sortable: false,
                    value: 'reference',
                },
                {
                    text: 'Account Number',
                    align: 'center',
                    sortable: false,
                    value: 'accountNumber',
                },
                {
                    text: 'Amount',
                    align: 'right',
                    sortable: false,
                    value: 'amount',
                    customComponent: 'Currency',
                },
                {
                    text: 'GST',
                    align: 'right',
                    sortable: false,
                    value: 'taxAmount',
                    customComponent: 'Currency',
                },
            ],
        };
    },
    methods: {
        flattenData() {
            this.data.transactions.map(transaction => {
                const useTransaction = {
                    ...transaction,
                    vendorCode: transaction.vendorCode || 'Error',
                };
                console.log('usetrans', useTransaction);
                this.lines.push(useTransaction);
                transaction.lines.map(line => {
                    const flatLine = {
                        ...line,
                        documentReference: undefined,
                        reference: line.documentReference,
                    };
                    this.lines.push(flatLine);
                });
            });
        },
    },

    mounted() {
        this.flattenData();
    },
};
</script>
