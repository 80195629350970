var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.project)?_c('Page',{attrs:{"title":_vm.title,"icon":"mdi-view-quilt"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('v-layout',[(_vm.project.APPurchasesPost)?_c('v-dialog',{attrs:{"max-width":700},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('btn',_vm._g({attrs:{"icon-code":"mdi-calculator","label":"Verify Postings","size":"small","color":"primary"}},_vm.verifyPostings(on)))]}}],null,false,1825969570),model:{value:(_vm.isShowVerifyDialog),callback:function ($$v) {_vm.isShowVerifyDialog=$$v},expression:"isShowVerifyDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.verifyPostingsResult.isMatched ? 'success' : 'error',"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v(" Verify Postings - "+_vm._s(_vm.verifyPostingsResult.isMatched ? 'PASSED' : 'FAILED')+" ")])],1),_c('v-card-text',{staticClass:"pa-4"},[(_vm.verifyPostingsResult.reportTotals)?_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"cols-6 text-center text-h6"},[_vm._v("SK Project")]),_c('v-col',{staticClass:"cols-6 text-center text-h6"},[_vm._v("QB General Ledger")])],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Expenses Ex-Tax")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{class:_vm.verifyPostingsResult.isMatchedExpenses
                                                ? 'success--text'
                                                : 'error--text',attrs:{"value":_vm.verifyPostingsResult.projectExpenses}})],1),_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Expenses")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{attrs:{"value":_vm.verifyPostingsResult.reportTotals.expenses}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"cols-3 text-right"}),_c('v-col',{staticClass:"cols-3"}),_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Vendor Credits")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{attrs:{"value":_vm.verifyPostingsResult.reportTotals.vendorCredits}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"cols-3 text-right"}),_c('v-col',{staticClass:"cols-3"}),_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Customer Refunds")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{attrs:{"value":_vm.verifyPostingsResult.reportTotals.refunds}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"cols-3 text-right"}),_c('v-col',{staticClass:"cols-3"}),_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Total")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{class:_vm.verifyPostingsResult.isMatchedExpenses
                                                ? 'success--text'
                                                : 'error--text',attrs:{"value":_vm.verifyPostingsResult.reportTotals.expenses +
                                            _vm.verifyPostingsResult.reportTotals.refunds +
                                            _vm.verifyPostingsResult.reportTotals.vendorCredits}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Income Ex-Tax")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{class:_vm.verifyPostingsResult.isMatchedIncome
                                                ? 'success--text'
                                                : 'error--text',attrs:{"value":_vm.verifyPostingsResult.projectIncome}})],1),_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Income")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{class:_vm.verifyPostingsResult.isMatchedIncome
                                                ? 'success--text'
                                                : 'error--text',attrs:{"value":_vm.verifyPostingsResult.reportTotals.income}})],1)],1),_c('v-divider',{staticClass:"my-5"}),_c('v-row',[_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Rent Ex-Tax")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{class:_vm.verifyPostingsResult.isMatchedRent
                                                ? 'success--text'
                                                : 'error--text',attrs:{"value":_vm.verifyPostingsResult.projectRentExpenses}})],1),_c('v-col',{staticClass:"cols-3 text-right"},[_vm._v("Rent Ex-Tax")]),_c('v-col',{staticClass:"cols-3"},[_c('currency',{class:_vm.verifyPostingsResult.isMatchedRent
                                                ? 'success--text'
                                                : 'error--text',attrs:{"value":_vm.verifyPostingsResult.reportTotals.rentExpenses}})],1)],1)],1):_vm._e()],1)],1)],1):_vm._e(),_c('btn',{attrs:{"preset":"done"},on:{"click":_vm.goBack}})],1)]},proxy:true}],null,false,2715253151)},[(_vm.project && _vm.files)?_c('v-expansion-panels',{staticClass:"ml-4",staticStyle:{"min-width":"600px","max-width":"1300px"},attrs:{"text-center":""}},[_c('panel',{attrs:{"title":"Upload Supporting Documents","isSuccess":!!_vm.filesComplete,"isShowSuccess":""}},[_c('storage-king-file-panel',{key:JSON.stringify(_vm.project),attrs:{"project":_vm.project},on:{"files-updated":_vm.updateFiles}})],1),_c('panel',{attrs:{"disabled":!_vm.filesComplete,"title":"Extract Expenses from MYOB Supplier Purchases Excel File","isSuccess":_vm.isSupplierPurchasesComplete,"isShowSuccess":""}},[_c('StorageKingAPPurchasesExtractPanel',{key:JSON.stringify(_vm.project),attrs:{"project":_vm.project},on:{"project-updated":_vm.updateProject}})],1),_c('panel',{attrs:{"disabled":!_vm.isSupplierPurchasesComplete,"title":"Link Invoices to Expenses","isSuccess":!!_vm.project && !!_vm.project.APPurchasesLink,"isShowSuccess":""}},[_c('StorageKingAPPurchasesLinkPanel',{key:JSON.stringify(_vm.project),attrs:{"project":_vm.project},on:{"project-updated":_vm.updateProject}})],1),_c('panel',{attrs:{"disabled":!_vm.project || !_vm.project.APPurchasesLink,"title":"Post Expenses to Quickbooks","isSuccess":_vm.project.APPurchasesPost && _vm.project.APPurchasesPost.isComplete,"isShowSuccess":""}},[_c('StorageKingAPPurchasesPostPanel',{key:JSON.stringify(_vm.project),attrs:{"project":_vm.project},on:{"project-updated":_vm.updateProject}})],1),_c('panel',{attrs:{"disabled":!_vm.filesComplete ||
                !(_vm.project.APPurchasesPost && _vm.project.APPurchasesPost.isComplete),"title":"Post Sales to Quickbooks","isSuccess":_vm.project.ARIncomeChargedPost &&
                _vm.project.ARIncomeChargedPost.actionStatus === 'success',"isShowSuccess":""}},[_c('StorageKingARIncomeChargedPanel',{key:JSON.stringify(_vm.project),attrs:{"project":_vm.project},on:{"project-updated":_vm.updateProject}})],1),_c('panel',{attrs:{"disabled":!(
                    _vm.project.ARIncomeChargedPost &&
                    _vm.project.ARIncomeChargedPost.actionStatus === 'success'
                ),"title":"Post Rent Transaction to Quickbooks","isSuccess":_vm.project.APRentPost && _vm.project.APRentPost.actionStatus === 'success',"isShowSuccess":""}},[_c('StorageKingAPRentPanel',{key:JSON.stringify(_vm.project),attrs:{"project":_vm.project},on:{"project-updated":_vm.updateProject}})],1)],1):_vm._e(),_c('note-form',{attrs:{"collection-name":"storageKingProject","collection-id":_vm.id}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }