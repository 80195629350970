<template>
    <div>

        <smart-table
            :headers="headers"
            :items="lines"
            class="elevation-1"
            dense
            :itemsPerPage="1000"
            :items-per-page-options="[10, 20, 1000]"
            :show-select="!isAPPurchasesLink"
            :single-select="!isAPPurchasesLink"
            itemKey="lineNumber"
            @item-selected="transactionSelected"
            @update:page="updatePageNumber"
            :pageNumber="tablePageNumber"
            @update:sort-by="updateSortBy"
            :sortBy="tableSortBy"
            @update:sort-desc="updateSortDesc"
            :sortDesc="tableSortDesc"
            hide-default-footer
            v-model="selected"
            :key="JSON.stringify(selected)"
        />
    </div>
</template>

<script>
export default {
    name: 'StorageKingSourceFileLinkTransactionList',
    props: {
        project: Object,
        selectedTransaction: Object,
        tablePageNumber: Number,
        tableSortBy: [String, Array],
        tableSortDesc: [Boolean, Array],
    },
    data() {
        return {
            lines: [],
            headers: [
                {
                    text: 'Vendor',
                    align: 'left',
                    sortable: true,
                    value: 'vendorName',
                },
                {
                    text: 'Reference',
                    align: 'left',
                    sortable: true,
                    value: 'reference',
                },
                {
                    text: 'Date',
                    align: 'center',
                    sortable: false,
                    value: 'transactionDate',
                },
                {
                    text: 'Amount',
                    align: 'right',
                    sortable: false,
                    value: 'amount',
                    customComponent: 'Currency',
                },

                {
                    text: 'Pages',
                    align: 'center',
                    sortable: false,
                    value: 'pages',
                },
            ],
            selected: [],
        };
    },
    methods: {
        async buildTransactionsData() {
            if (
                this.project &&
                this.project.APPurchasesExtract &&
                this.project.APPurchasesExtract.extractedData &&
                this.project.APPurchasesExtract.extractedData.transactions
            ) {
                const { transactions } = this.project.APPurchasesExtract.extractedData;
                const lines = transactions.map((transaction, lineNumber) => {
                    const { amount, taxAmount } = transaction.lines
                        .filter((line) => line.amount)
                        .reduce(
                            (accum, line) => ({
                                amount: accum.amount + line.amount,
                                taxAmount: accum.taxAmount + line.taxAmount,
                            }),
                            { amount: 0, taxAmount: 0 },
                        );
                    return {
                        vendorName: transaction.vendorName.substr(0, 15),
                        reference: transaction.reference,
                        transactionDate: transaction.transactionDate,
                        amount: amount + taxAmount,
                        pages: transaction.pages ? transaction.pages.join(', ') : '',
                        lineNumber,
                    };
                });
                this.lines = lines;

                if (this.selectedTransaction) {
                    this.matchSelected();
                }
            }
        },
        transactionSelected(...line) {
            console.log('transactionSelected', JSON.stringify(line, null, 2));
            this.$emit('transaction-selected', line);
        },
        updatePageNumber(pageNumber) {
            this.$emit('update:table-page', pageNumber);
        },
        updateSortBy(sortBy) {
            this.$emit('update:table-sort-by', sortBy);
        },
        updateSortDesc(sortDesc) {
            this.$emit('update:table-sort-desc', sortDesc);
        },
        matchSelected() {
            const { pages, ...selectedWithoutPages } = this.selectedTransaction;
            console.log('matchSelected 11', selectedWithoutPages, this.lines);
            const matchingRecord = this.lines.find((oneLine, lineNum) => {
                const { pages, ...oneLineWithoutPages } = oneLine;
                console.log('matchSelected 21', oneLine, oneLineWithoutPages, JSON.stringify(selectedWithoutPages) === JSON.stringify(oneLineWithoutPages), lineNum);

                return JSON.stringify(selectedWithoutPages) === JSON.stringify(oneLineWithoutPages);
            });
            console.log('matchSelected 22', matchingRecord);

            if(matchingRecord) this.selected = [matchingRecord];
        }
    },
    computed: {
        isAPPurchasesLink() {
            return !!this.project && !!this.project.APPurchasesLink;
        },
    },
    async created() {
        console.log('transactionlist project 111', this.project);
        await this.buildTransactionsData();
        console.log('transactionlist project 222', {
            selectedTransaction: this.selectedTransaction,
            line2: this.lines,
        });
    },
};
</script>
