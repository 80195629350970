<template>
    <Page :title="title" icon="mdi-view-quilt" v-if="project">
        <v-expansion-panels
            style="min-width: 600px; max-width: 1300px"
            text-center
            v-if="project && files"
            class="ml-4"
        >
            <panel title="Upload Supporting Documents" :isSuccess="!!filesComplete" isShowSuccess>
                <storage-king-file-panel
                    :project="project"
                    @files-updated="updateFiles"
                    :key="JSON.stringify(project)"
                />
            </panel>
            <panel
                :disabled="!filesComplete"
                title="Extract Expenses from MYOB Supplier Purchases Excel File"
                :isSuccess="isSupplierPurchasesComplete"
                isShowSuccess
            >
                <StorageKingAPPurchasesExtractPanel
                    :project="project"
                    @project-updated="updateProject"
                    :key="JSON.stringify(project)"
                />
            </panel>
            <panel
                :disabled="!isSupplierPurchasesComplete"
                title="Link Invoices to Expenses"
                :isSuccess="!!project && !!project.APPurchasesLink"
                isShowSuccess
            >
                <StorageKingAPPurchasesLinkPanel
                    :project="project"
                    @project-updated="updateProject"
                    :key="JSON.stringify(project)"
                />
            </panel>
            <panel
                :disabled="!project || !project.APPurchasesLink"
                title="Post Expenses to Quickbooks"
                :isSuccess="project.APPurchasesPost && project.APPurchasesPost.isComplete"
                isShowSuccess
            >
                <StorageKingAPPurchasesPostPanel
                    :project="project"
                    @project-updated="updateProject"
                    :key="JSON.stringify(project)"
                />
            </panel>
            <panel
                :disabled="
                    !filesComplete ||
                    !(project.APPurchasesPost && project.APPurchasesPost.isComplete)
                "
                title="Post Sales to Quickbooks"
                :isSuccess="
                    project.ARIncomeChargedPost &&
                    project.ARIncomeChargedPost.actionStatus === 'success'
                "
                isShowSuccess
                ><StorageKingARIncomeChargedPanel
                    :project="project"
                    @project-updated="updateProject"
                    :key="JSON.stringify(project)"
                />
            </panel>
            <panel
                :disabled="
                    !(
                        project.ARIncomeChargedPost &&
                        project.ARIncomeChargedPost.actionStatus === 'success'
                    )
                "
                title="Post Rent Transaction to Quickbooks"
                :isSuccess="project.APRentPost && project.APRentPost.actionStatus === 'success'"
                isShowSuccess
            >
                <StorageKingAPRentPanel
                    :project="project"
                    @project-updated="updateProject"
                    :key="JSON.stringify(project)"
                />
            </panel>
            <!--            <panel-->
            <!--                :disabled="false"-->
            <!--                title="Post Cash Receipts to Quickbooks"-->
            <!--                :isSuccess="-->
            <!--                    project.GLCashReceiptsPost && project.GLCashReceiptsPost.actionStatus === 'success'-->
            <!--                "-->
            <!--            ><StorageKingGLCashReceiptsPanel-->
            <!--                :project="project"-->
            <!--                @project-updated="updateProject"-->
            <!--                :key="JSON.stringify(project)"-->
            <!--            />-->
            <!--            </panel>-->
        </v-expansion-panels>
        <note-form collection-name="storageKingProject" :collection-id="id" />
        <template v-slot:header>
            <v-layout>
                <v-dialog
                    v-if="project.APPurchasesPost"
                    v-model="isShowVerifyDialog"
                    class=""
                    :max-width="700"
                >
                    <template v-slot:activator="{ on }">
                        <btn
                            icon-code="mdi-calculator"
                            label="Verify Postings"
                            size="small"
                            color="primary"
                            v-on="verifyPostings(on)"
                        />
                    </template>
                    <v-card>
                        <v-toolbar
                            :color="verifyPostingsResult.isMatched ? 'success' : 'error'"
                            dense
                            flat
                        >
                            <v-toolbar-title class="white--text">
                                Verify Postings -
                                {{ verifyPostingsResult.isMatched ? 'PASSED' : 'FAILED' }}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pa-4">
                            <v-container v-if="verifyPostingsResult.reportTotals">
                                <v-row>
                                    <v-col class="cols-6 text-center text-h6">SK Project</v-col>
                                    <v-col class="cols-6 text-center text-h6"
                                        >QB General Ledger</v-col
                                    >
                                </v-row>
                                <v-divider class="my-5" />
                                <v-row>
                                    <v-col class="cols-3 text-right">Expenses Ex-Tax</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.projectExpenses"
                                            :class="
                                                verifyPostingsResult.isMatchedExpenses
                                                    ? 'success--text'
                                                    : 'error--text'
                                            "
                                        />
                                    </v-col>
                                    <v-col class="cols-3 text-right">Expenses</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.reportTotals.expenses"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="cols-3 text-right"></v-col>
                                    <v-col class="cols-3"></v-col>
                                    <v-col class="cols-3 text-right">Vendor Credits</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.reportTotals.vendorCredits"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="cols-3 text-right"></v-col>
                                    <v-col class="cols-3"></v-col>
                                    <v-col class="cols-3 text-right">Customer Refunds</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.reportTotals.refunds"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="cols-3 text-right"></v-col>
                                    <v-col class="cols-3"></v-col>
                                    <v-col class="cols-3 text-right">Total</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="
                                                verifyPostingsResult.reportTotals.expenses +
                                                verifyPostingsResult.reportTotals.refunds +
                                                verifyPostingsResult.reportTotals.vendorCredits
                                            "
                                            :class="
                                                verifyPostingsResult.isMatchedExpenses
                                                    ? 'success--text'
                                                    : 'error--text'
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-divider class="my-5" />
                                <v-row>
                                    <v-col class="cols-3 text-right">Income Ex-Tax</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.projectIncome"
                                            :class="
                                                verifyPostingsResult.isMatchedIncome
                                                    ? 'success--text'
                                                    : 'error--text'
                                            "
                                        />
                                    </v-col>
                                    <v-col class="cols-3 text-right">Income</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.reportTotals.income"
                                            :class="
                                                verifyPostingsResult.isMatchedIncome
                                                    ? 'success--text'
                                                    : 'error--text'
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-divider class="my-5" />
                                <v-row>
                                    <v-col class="cols-3 text-right">Rent Ex-Tax</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.projectRentExpenses"
                                            :class="
                                                verifyPostingsResult.isMatchedRent
                                                    ? 'success--text'
                                                    : 'error--text'
                                            "
                                        />
                                    </v-col>
                                    <v-col class="cols-3 text-right">Rent Ex-Tax</v-col>
                                    <v-col class="cols-3">
                                        <currency
                                            :value="verifyPostingsResult.reportTotals.rentExpenses"
                                            :class="
                                                verifyPostingsResult.isMatchedRent
                                                    ? 'success--text'
                                                    : 'error--text'
                                            "
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <btn preset="done" @click="goBack" />
            </v-layout>
        </template>
    </Page>
</template>
<script>
import api from '@/api';
import StorageKingFilePanel from '@/views/storageKing/components/FilePanel';
import StorageKingAPPurchasesExtractPanel from '@/views/storageKing/components/APPurchasesExtract/Panel';
import StorageKingAPPurchasesLinkPanel from '@/views/storageKing/components/APPurchasesLink/Panel';
import StorageKingAPPurchasesPostPanel from '@/views/storageKing/components/APPurchasesPost/Panel';
import StorageKingARIncomeChargedPanel from '@/views/storageKing/components/ARIncomeCharged/Panel';
import StorageKingAPRentPanel from '@/views/storageKing/components/APRent/Panel';
import NoteForm from '@/components/common/note/NoteForm';
//import StorageKingGLCashReceiptsPanel from '@/components/app/storageKing/GLCashReceipts/Panel'

import Panel from '@/components/common/Panel';
import Currency from '@/components/format/Currency';

export default {
    name: 'StorageKingProjectDetail',
    components: {
        Currency,
        StorageKingFilePanel,
        StorageKingAPPurchasesExtractPanel,
        StorageKingAPPurchasesLinkPanel,
        StorageKingAPPurchasesPostPanel,
        StorageKingARIncomeChargedPanel,
        StorageKingAPRentPanel,
        NoteForm,
        //StorageKingGLCashReceiptsPanel,
        Panel,
    },
    data() {
        return {
            isShowLoading: false,
            errorMessage: '',
            isShowError: false,
            title: 'Storage King Project',
            id: this.$route.params.id,
            project: null,
            files: null,
            isMYOBPurchasePayableComplete: false,
            APPurchasesActionFlowId: null,
            isSupplierPurchasesComplete: null,
            isIncomeChargedBalanced: null,
            filesComplete: false,
            isShowVerifyDialog: false,
            verifyPostingsResult: {},
        };
    },
    methods: {
        async updateProject(project) {
            this.$store.dispatch('setLoading', 'Retrieving results.');

            if (project) {
                this.project = { ...project };
            } else {
                const result = await api.get(this.$store, `storageking/project/${this.id}`);
                if (result.success === false) {
                    console.log('error happened', result);
                    throw new Error('project load failed');
                }
                this.project = result.data;
            }
            if (this.project && this.project.costCentreLink) {
                this.title = `Storage King Project - ${this.project.costCentreLink.text} ${this.project.yearMonth}`;
            }
            this.isSupplierPurchasesComplete =
                this.project &&
                this.project.APPurchasesExtract &&
                this.project.APPurchasesExtract.isComplete;
            this.APPurchasesActionFlowId =
                this.project &&
                this.project.APPurchasesExtract &&
                this.project.APPurchasesExtract.extractedData
                    ? this.project.APPurchasesExtract.actionFlowRootId
                    : null;
            this.isIncomeChargedBalanced =
                this.project &&
                this.project.ARIncomeChargedExtract &&
                this.project.ARIncomeChargedExtract.extractedData
                    ? this.project.ARIncomeChargedExtract.extractedData.balanced
                    : false;

            this.$store.dispatch('setLoading');
        },
        async updateFiles(files) {
            if (files) {
                this.files = [...files];
                console.log('updatefiles 1');
            } else {
                this.files = (await api.get(this.$store, `storageking/files/${this.id}`)).data;
                console.log('updatefiles 2');
            }

            console.log('project detail files', this.files);
            this.filesComplete =
                this &&
                this.files &&
                this.files.find(
                    (fileObj) => fileObj.file.source.fileType === 'MYOB Supplier Purchases',
                ) &&
                this.files.find(
                    (fileObj) => fileObj.file.source.fileType === 'Purchases Original Copies',
                ) &&
                this.files.find(
                    (fileObj) => fileObj.file.source.fileType === 'Storeman Income Charged',
                ) &&
                this.files.find((fileObj) => fileObj.file.source.fileType === 'Rent Invoice');
        },
        goBack() {
            this.$router.push(`../project?isHistory=true`);
        },
        verifyPostings(ev) {
            return {
                click: async (...params) => {
                    console.log('running verify');
                    this.$store.dispatch('setLoading', 'Verifying Postings');
                    this.verifyPostingsResult = (
                        await api.get(this.$store, `storageking/verify/${this.id}`)
                    ).data;
                    this.$store.dispatch('setLoading');
                    console.log('verify result', this.verifyPostingsResult);
                    return ev.click(...params);
                },
            };
        },
    },
    created() {
        this.updateProject();
        this.updateFiles();
    },
};
</script>
