<template>
    <fragment>
        <fragment v-if="!project.APRentPost || project.APRentPost.actionStatus !== 'success'">
            <smart-form v-if="formSchema" v-model="formModel" :schema="formSchema" />


            <btn color="success" @click="postRentTransaction" label="Post Rent Transaction to Quickbooks" :disabled="!!(formModel.reference === '' || formModel.totalAmount === 0)"  />

        </fragment>
        <v-container v-else>
            <smart-table v-if="items.length" :headers="headers" :items="items" class="elevation-1" dense hide-default-footer :itemsPerPage="10" />
            <btn
                color="success"
                @click="
                    () => {
                        isMore = !isMore;
                    }
                "
                small
            >
                {{ isMore ? 'Less' : 'More' }}
            </btn>

            <action-flow-step v-if="isMore" :AFId="project.APRentPost.actionFlowRootId" :title="project.APRentPost.subject" step="1" :key="project.APRentPost.actionFlowRootId" :runStep="runActionFlow" />
        </v-container>
    </fragment>
</template>
<script>
const formSchema = {
    reference: {
        component: 'text-field',
        label: 'Invoice #',
        cols: 4,
    },
    totalAmount: {
        component: 'currency-field',
        label: 'Payment Amount',
        precision: 2,
        cols: 4,
    },
};

import api from '@/api';
import actionFlowStep from '@/components/common/ActionFlowStep';

export default {
    name: 'StorageKingPostRentPanel',
    props: { project: Object },
    components: { actionFlowStep },
    data() {
        return {
            formSchema,
            formModel: {
                totalAmount: 0,
                reference: '',
            },

            title: 'Storage King Project',
            headers: [
                {
                    text: 'Date',
                    align: 'center',
                    sortable: false,
                    value: 'transactionDate',
                },
                {
                    text: 'Vendor',
                    align: 'left',
                    sortable: false,
                    value: 'vendorName',
                },
                {
                    text: 'Reference',
                    align: 'left',
                    sortable: false,
                    value: 'documentReference',
                },
                {
                    text: 'Amount',
                    align: 'right',
                    sortable: false,
                    value: 'totalAmount',
                    customComponent: 'Currency',
                },
                {
                    text: 'Tax',
                    align: 'right',
                    sortable: false,
                    value: 'taxAmount',
                    customComponent: 'Currency',
                },
            ],
            items: [],
            isMore: false,
            document: null,
        };
    },
    methods: {
        async postRentTransaction() {
            if (this.formModel.reference && this.formModel.totalAmount) {
                this.$store.dispatch('setLoading', 'Post Rent Invoice To Quickbooks');
                const projectObj = await api.post(this.$store, `storageking/postaprent`, {
                    storageKingProjectId: this.project.id,
                    vendorCode: 'NDB',
                    reference: this.formModel.reference,
                    totalAmount: this.formModel.totalAmount,
                });
                console.log('finished', projectObj);
                this.$emit('project-updated', projectObj.data);
            } else {
                this.$store.dispatch('setError', 'You must enter the Total and Reference');
            }
            this.$store.dispatch('setLoading');
        },
        async runActionFlow() {
            if (this.project && this.project.APRentPost) {
                const projectObj = await api.post(this.$store, `storageking/retrypostaprent`, {
                    actionFlowId: this.project.APRentPost.actionFlowId,
                    storageKingProjectId: this.project.id,
                });

                const project = projectObj.data;
                this.$emit('project-updated', project);
            }
        },
        async init() {
            if (this.project && this.project.APRentPost) {
                const result = await api.get(this.$store, `document/${this.project.APRentPost.documentId}`);
                if (result.data) {
                    this.document = result.data;

                    this.items = [
                        {
                            transactionDate: this.document.documentDate,
                            vendorName: this.document.vendorLink.text,
                            documentReference: this.document.documentReference,
                            totalAmount: this.document.accountsData.TotalAmt,
                            taxAmount: this.document.accountsData.TxnTaxDetail.TotalTax,
                        },
                    ];
                }
            }
        },
        log() {
            console.log('amountTotal', this.totalAmount, typeof this.totalAmount);
        },

    },



    mounted() {
        this.init();
    },
};
</script>
