<template>
    <v-container>
        <preview-raw-data
            v-if="extractedData"
            :storage-king-project-id="project.id"
            :data="extractedData"
            :missing-item-codes="missingItemCodes"
        />
        <btn
            v-if="
                extractedData &&
                    extractedData.balanced &&
                    (!project.ARIncomeChargedPost ||
                        project.ARIncomeChargedPost.actionStatus !== 'success')
            "
            color="success"
            @click="postARTransaction"
            small
        >
            Post Sales to Quickbooks
        </btn>
        <btn
            v-if="extractedData"
            color="success"
            @click="
                () => {
                    isMore = !isMore;
                }
            "
            small
        >
            {{ isMore ? 'Less' : 'More' }}
        </btn>

        <fragment v-if="file && file.metadata && (!extractedData || isMore)">
            <v-container>
                <file-download-field :file-id="file.id" />
                <btn :disabled="!!breakDescription" @click="extractData" color="success" small>
                    <v-progress-circular
                        v-if="!!breakDescription"
                        indeterminate
                        :size="20"
                        :width="4"
                        class="mr-3"
                    />
                    {{ breakDescription ? breakDescription : 'Extract Data' }}
                </btn>
                <action-flow-step
                    v-if="isMore && extractActionFlowRootId"
                    :AFId="extractActionFlowRootId"
                    title="Extracted Data Action Flow"
                    step="1"
                    hideRun
                    :key="extractActionFlowRootId"
                />
                <action-flow-step
                    v-if="isMore && postActionFlowRootId"
                    :AFId="postActionFlowRootId"
                    title="Post Sale to Quickbooks Action Flow"
                    step="1"
                    hideRun
                    :key="postActionFlowRootId"
                />
            </v-container>
        </fragment>
    </v-container>
</template>
<script>
import api from '@/api';
import ActionFlowStep from '@/components/common/ActionFlowStep';
import PreviewRawData from './PreviewRawData';
import { loadFilesBySource } from '../common';

export default {
    name: 'StorageKingPostSalesPanel',
    props: { project: Object },
    components: { ActionFlowStep, PreviewRawData },
    data() {
        return {
            file: null,
            extractActionFlowRootId: null,
            postActionFlowRootId: null,
            extractedData: null,
            missingItemCodes: null,
            breakDescription: '',
            isMore: false,
            showAlert: false,
        };
    },
    methods: {
        async init() {
            this.extractActionFlowRootId = null;
            this.extractActionFlowRootId =
                this.project && this.project.ARIncomeChargedExtract
                    ? this.project.ARIncomeChargedExtract.actionFlowRootId
                    : null;

            this.postActionFlowRootId =
                this.project && this.project.ARIncomeChargedPost
                    ? this.project.ARIncomeChargedPost.actionFlowRootId
                    : null;

            console.log('project', this.project);
            const [fileObj] = await loadFilesBySource(
                this.$store,
                'storageKingProject',
                this.project.id,
                'Storeman Income Charged'
            );

            this.file = fileObj.file;

            if (
                this.project &&
                this.project.ARIncomeChargedExtract &&
                this.project.ARIncomeChargedExtract.errorMessage
            ) {
                this.$store.dispatch('setError', this.project.ARIncomeChargedExtract.errorMessage);
            }

            if (
                this.project &&
                this.project.ARIncomeChargedExtract &&
                this.project.ARIncomeChargedExtract.extractedData
            ) {
                this.extractedData = this.project.ARIncomeChargedExtract.extractedData;
                this.missingItemCodes = this.project.ARIncomeChargedExtract.missingItemCodes || [];
            }
        },
        async extractData({ resumeRootId }) {
            try {
                this.$store.dispatch(
                    'setLoading',
                    'Extracting Sales from Income Charged Spreadsheet',
                );

                const storageKingProjectData = await api.post(
                    this.$store,
                    `storageking/extractarincomecharged`,
                    {
                        projectId: this.project.id,
                        resumeRootId,
                    },
                );
                if (storageKingProjectData.data && storageKingProjectData.data.resumeRootId) {
                    this.breakDescription = storageKingProjectData.data.breakDescription;
                    return await this.extractData({
                        resumeRootId: storageKingProjectData.data.resumeRootId,
                    });
                }
                this.$store.dispatch('setLoading');
                this.breakDescription = '';
                this.$emit('project-updated', storageKingProjectData.data);
            } catch (e) {
                console.error('Error Extracting Data`', e.message);
            }
        },
        async postARTransaction() {
            this.$store.dispatch('setLoading', 'Post A/R Invoices To Quickbooks');

            const projectObj = await api.post(this.$store, `storageking/postarincomecharged`, {
                storageKingProjectId: this.project.id,
            });
            this.$store.dispatch('setLoading');

            console.log('finished', projectObj);
            this.$emit('project-updated', projectObj.data);
        },
    },
    created() {
        console.log('income charged created');
        this.init();
    },
};
</script>
