<template>
    <fragment v-if="project">
        <fragment v-if="!project.APPurchasesPost || !project.APPurchasesPost.transactions">
            <btn color="success" @click="() => postTransactions()" small
                >Post Transactions to Quickbooks</btn
            >
        </fragment>
        <v-container v-else>
            <fragment v-if="isComplete">
                <smart-table
                    :headers="headers"
                    :items="items"
                    class="elevation-1"
                    dense
                    :itemsPerPage="10"
                />
            </fragment>
            <div v-else>One or more AP transactions failed to post</div>
            <btn
                color="success"
                @click="
                    () => {
                        isMore = !isMore;
                    }
                "
                small
            >
                {{ isMore ? 'Less' : 'More' }}
            </btn>

            <fragment v-if="isMore">
                <action-flow-step
                    v-for="(af, idx) in project.APPurchasesPost.transactions"
                    :AFId="af.actionFlowRootId"
                    :title="af.subject"
                    :step="`${idx}`"
                    :key="af.actionFlowRootId"
                    :runStep="runActionFlow(idx)"
                />
            </fragment>
        </v-container>
    </fragment>
</template>
<script>
import api from '@/api';

import ActionFlowStep from '@/components/common/ActionFlowStep';

export default {
    name: 'StorageKingPostAPPanel',
    props: { project: Object },
    components: { ActionFlowStep },
    data() {
        return {
            title: 'Storage King Project',
            isComplete: false,
            isMore: false,
            headers: [
                {
                    text: 'Date',
                    align: 'center',
                    sortable: false,
                    value: 'transactionDate',
                },
                {
                    text: 'Vendor',
                    align: 'left',
                    sortable: false,
                    value: 'vendorName',
                },
                {
                    text: 'Reference',
                    align: 'left',
                    sortable: false,
                    value: 'documentReference',
                },
                {
                    text: 'Amount',
                    align: 'right',
                    sortable: false,
                    value: 'totalAmount',
                    customComponent: 'Currency',
                },
                {
                    text: 'Tax',
                    align: 'right',
                    sortable: false,
                    value: 'taxAmount',
                    customComponent: 'Currency',
                },
            ],
            items: [],
        };
    },
    methods: {
        async postTransactions(moreMessage = '') {
            this.$store.dispatch('setLoading', `Post A/P Invoices To Quickbooks ${moreMessage}`);

            const projectObj = await api.post(this.$store, `storageking/postappurchases`, {
                storageKingProjectId: this.project.id,
            });
            this.$store.dispatch('setLoading');
            const project = projectObj.data;
            this.$emit('project-updated', project);
        },
        runActionFlow(transactionNumber) {
            return async () => {
                if (this.project) {
                    const projectObj = await api.post(
                        this.$store,
                        `storageking/retrypostappurchases`,
                        {
                            actionFlowId: this.project.APPurchasesPost.transactions[
                                transactionNumber
                            ].actionFlowId,
                            storageKingProjectId: this.project.id,
                            transactionNumber,
                        },
                    );
                    const project = projectObj.data;
                    this.$emit('project-updated', project);
                }
            };
        },
        init() {
            if (this.project && this.project.APPurchasesPost && this.project.APPurchasesLink) {
                this.isComplete = this.project.APPurchasesPost.isComplete;
                this.items = [...this.project.APPurchasesLink];
            }

            if (
                this.project &&
                this.project.APPurchasesPost &&
                this.project.APPurchasesPost.isMoreToProcess
            ) {
                this.postTransactions(this.project.APPurchasesPost.moreMessage);
            }
        },
    },
    mounted() {
        this.init();
        console.log('isComplete', this.isComplete);
    },
};
</script>
