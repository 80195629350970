<template>
    <v-container>
        <v-container>
            {{ pages.length }} / {{ numPages }} pages allocated
            <btn color="success" @click="confirmComplete" label="Complete" />
            <Confirm ref="confirm"></Confirm>
            <v-switch v-model="isAllowDupes" label="Reuse Pages"></v-switch>
        </v-container>

        <fragment v-if="selectedTransaction">
            <v-card class="my-4">
                <v-card-title> Selected Transaction </v-card-title>
                <v-card-text class="d-flex flex-column">
                    <v-container>
                        <v-row>
                            {{ selectedTransaction.vendorName }}
                        </v-row>
                        <v-row>
                            {{ selectedTransaction.reference }}
                        </v-row>
                        <v-row>
                            {{ selectedTransaction.transactionDate }}
                        </v-row>
                        <v-row>
                            <Currency v-if="selectedTransaction" :value="selectedTransaction.amount" />
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            <btn v-for="counter in numPages" :key="counter" class="ma-1" fab size="small" :disabled="pageDisabled(counter)" :color="selectedInvoicePages.includes(counter) ? 'success' : 'primary'" @click="selectPage(counter)" :label="`${counter}`" :id="`${counter}`" />
        </fragment>
    </v-container>
</template>
<script>
import api from '@/api';
import Currency from '../../../../components/format/Currency';
import Confirm from '../../../../components/form/buttons/Confirm';

export default {
    name: 'StorageKingPurchasePayablePanel',
    props: { project: Object, selectedTransaction: Object, pages: Array, numPages: Number },
    components: { Confirm, Currency },
    data() {
        return {
            isAllowDupes: false,
            selectedInvoicePages: [],
        };
    },
    methods: {
        pageDisabled(pageNumber) {
            // no item selected
            if (!this.selectedTransaction) return true;

            // get selected pages for transaction.
            let pages;
            try {
                if (!this.project || !this.project.APPurchasesExtract || !this.project.APPurchasesExtract.extractedData || !this.project.APPurchasesExtract.extractedData.transactions || !this.project.APPurchasesExtract.extractedData.transactions[this.selectedTransaction.lineNumber]) return true;

                pages = this.project.APPurchasesExtract.extractedData.transactions[this.selectedTransaction.lineNumber].pages || [];
                if (JSON.stringify(this.selectedInvoicePages) !== JSON.stringify(pages)) this.selectedInvoicePages = [...pages];
            } catch (e) {
                return true;
            }
            return this.pages && this.pages.includes(pageNumber) && !this.isAllowDupes && !(this.selectedTransaction && Object.prototype.hasOwnProperty.call(this.selectedTransaction, 'lineNumber') && pages.includes(pageNumber));
        },
        async setTransactionSourcePage(pageNumber) {
            const projectObj = await api.post(this.$store, `storageking/settransactionsourcepage`, {
                projectId: this.project.id,
                section: 'APPurchasesExtract',
                lineNumber: this.selectedTransaction.lineNumber,
                pageNumber,
                value: !this.selectedInvoicePages.includes(pageNumber),
            });

            return projectObj.data;
        },
        async selectPage(pageNumber) {
            console.log('selectpage', 1);
            if (this.selectedTransaction) {
                console.log('selectpage', 2);
                const project = await this.setTransactionSourcePage(pageNumber);
                console.log('selectpage', 3, this.selectedTransaction);
                this.$emit('project-updated', project);
            }
        },
        async confirmComplete() {
            if (
                this.project &&
                (await this.$refs.confirm.open('Complete and Slice Document', 'Are you sure?', {
                    width: 400,
                }))
            ) {
                this.$store.dispatch('setLoading', 'Generating Transactions and Supporting Documents');

                const result = await api.post(this.$store, `storageking/linkappurchases`, {
                    storageKingProjectId: this.project.id,
                });

                const { project, success, actionFlow } = result.data;
                this.$store.dispatch('setLoading');

                if (success) this.$emit('confirm-complete', project);
                else {
                    this.$store.dispatch('setError', 'Complete failed. Click "Show Results" for more information.');
                    this.$emit('confirm-failed-action-flow', actionFlow);
                }
            }
        },
    },
};
</script>
