<template>
    <v-expansion-panel :disabled="disabled">
        <v-expansion-panel-header v-slot="{ open }" class="d-flex" style="XXbackground-color: blue;">
            <v-container class="ma-0 pa-0 d-flex" style="XXbackground-color: red;">
                <v-row no-gutters>
                    <v-col :cols="titleCols" class="ma-2">
                        {{ title }}
                        <slot name="title"></slot>
                        <success-icon v-if="isShowSuccess" :isSuccess="isSuccess" />
                    </v-col>
                    <v-col :cols="(12 - titleCols) % 12" class="text--secondary no-gutters">
                        <v-fade-transition leave-absolute>
                            <slot v-if="open" name="opened-header"></slot>
                            <slot v-else name="closed-header"></slot>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-container>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <slot></slot>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
import SuccessIcon from './SuccessIcon';

const anyType = [Boolean, Number, String];

export default {
    name: 'Panel',
    props: {
        title: String,
        isSuccess: anyType,
        disabled: anyType,
        isShowSuccess: Boolean,
        titleCols: {
            type: String,
            default: "12",
        },
    },
    components: {
        SuccessIcon,
    },
};
</script>