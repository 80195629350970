<template>
    <v-container>
        <preview-raw-data v-if="extractedData" :data="extractedData"></preview-raw-data>
        <btn
            v-if="extractedData"
            color="success"
            @click="
                () => {
                    isMore = !isMore;
                }
            "
            small
        >
            {{ isMore ? 'Less' : 'More' }}
        </btn>
        <v-container v-if="(isMore || !extractedData) && file && file.metadata" class="d-flex">
            <file-download-field
                collectionName="storageKingProject"
                :collectionId="project.id"
                file-type="MYOB Supplier Purchases"
                stamp-page-numbers
            />
            <btn
                color="success"
                class="ml-2"
                :disabled="!!breakDescription"
                @click="extractData"
                small
            >
                <v-progress-circular
                    v-if="!!breakDescription"
                    indeterminate
                    :size="20"
                    :width="4"
                    class="mr-3"
                />
                {{ breakDescription ? breakDescription : 'Extract Data' }}
            </btn>
        </v-container>
        <action-flow-step
            v-if="isMore && actionFlowRootId"
            :AFId="actionFlowRootId"
            title="Extracted Data Action Flow"
            step="1"
            hideRun
            :key="actionFlowRootId"
        />
    </v-container>
</template>
<script>
import api from '@/api';
import ActionFlowStep from '@/components/common/ActionFlowStep';
import PreviewRawData from './PreviewRawData';
import { loadFilesBySource } from '../common';

export default {
    name: 'StorageKingSupplierPurchasesPanel',
    props: { project: Object },
    components: { ActionFlowStep, PreviewRawData },
    data() {
        return {
            storageKingProject: null,
            fileId: null,
            file: null,
            actionFlowRootId: null,
            extractedData: null,
            breakDescription: '',
            isMore: false,
            showAlert: false,
        };
    },
    methods: {
        async init() {
            console.log('purch pay init', this.project.id);

            this.actionFlowRootId = null;
            this.actionFlowRootId =
                this.project && this.project.APPurchasesExtract
                    ? this.project.APPurchasesExtract.actionFlowRootId
                    : null;

            const [fileObj] = await loadFilesBySource(
                this.$store,
                'storageKingProject',
                this.project.id,
                'MYOB Supplier Purchases',
            );
            console.log('purch pay ', { file: fileObj.file });

            this.file = fileObj.file;
            if (
                this.project &&
                this.project.APPurchasesExtract &&
                this.project.APPurchasesExtract.errorMessage
            ) {
                this.$store.dispatch('setError', this.project.APPurchasesExtract.errorMessage);
            }

            if (
                this.project &&
                this.project.APPurchasesExtract &&
                this.project.APPurchasesExtract.extractedData
            ) {
                this.extractedData = this.project.APPurchasesExtract.extractedData;
            }
        },
        async extractData({ resumeRootId }) {
            try {
                const storageKingProjectData = await api.post(
                    this.$store,
                    `storageking/extractappurchases/`,
                    {
                        projectId: this.project.id,
                        resumeRootId,
                    },
                );
                if (storageKingProjectData.data && storageKingProjectData.data.resumeRootId) {
                    this.breakDescription = storageKingProjectData.data.breakDescription;
                    return await this.extractData({
                        resumeRootId: storageKingProjectData.data.resumeRootId,
                    });
                    // console.log("**********************",{extractResult});
                    // return extractResult;
                }
                this.breakDescription = '';
                this.$emit('project-updated', storageKingProjectData.data);
            } catch (e) {
                console.error('Error Extracting Data`', e.message);
            }
        },
    },
    created() {
        console.log('purch pay created');
        this.init();
    },
};
</script>
